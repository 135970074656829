interface IProps {
  tag: string
  title: string
}

export function Feature({ title, tag }: IProps): JSX.Element {
  return (
    <div className="feature">
      <h3>{title}</h3>
      <img src={`/assets/${tag}.png`} alt={tag} />
    </div>
  )
}
