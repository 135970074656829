import { Video } from "../../../../common/types"

interface IProps {
  title: string
  type: "image" | "video" | "pdf"
  source: string
  className?: string
  setVideoUrl?: (value?: string) => void
  video?: Video
  id: string
  link?: string
}

export function Feature({ title, source, type, setVideoUrl, video, id, className = "", link }: IProps): JSX.Element {
  const handleOnClick = () => {
    if (type === "video") {
      setVideoUrl && setVideoUrl(video?.intro)
    } else if (type === "pdf" && link) {
      window.open(link, "_blank", "noopener,noreferrer")
    }
  }
  return (
    <div
      className={`feature ${className} ${type}`}
      onClick={handleOnClick}
      data-amplify-analytics-on="click"
      data-amplify-analytics-name="click"
      data-amplify-analytics-attrs={`id:${id}`}
    >
      <img className="extra-padding" src={source} alt={title} />
      <h3>{title}</h3>
    </div>
  )
}
