import { PdsFileOutIcon, PdsHamburgerMenuIcon } from "@podium-design-system/react-icons"
import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { whiteColor } from "../../colors"
import { isTabletOrDesktop } from "../../utils"
import { Logo } from "../Logo"
import { useAmplifyAuth } from "../../auth"

import "./index.scss"

export function Navbar(): JSX.Element {
  const { pathname } = useLocation()
  const [isMobile, setIsMobile] = useState<boolean>()
  const { handleSignout } = useAmplifyAuth()

  const configureIsMobile = useCallback(() => {
    setIsMobile(!isTabletOrDesktop())
  }, [])

  const configureStickyNavbar = useCallback(() => {
    const navbar = document.getElementById("navbar")

    if (window.scrollY > 50 && navbar) {
      navbar.classList.add("scroll")
    } else {
      navbar?.classList.remove("scroll")
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", configureIsMobile, false)
    window.addEventListener("scroll", configureStickyNavbar, false)
    configureIsMobile()

    return () => {
      window.removeEventListener("scroll", configureStickyNavbar)
      window.removeEventListener("resize", configureIsMobile, false)
    }
  }, [])

  return (
    <div className="navbar" id="navbar">
      <Logo />
      <div className="right">
        {isMobile && <PdsHamburgerMenuIcon hexColor={whiteColor} fixedSize="24px" className="show-menu" />}
        <div className="flex menu-items">
          {pathname === "/" && (
            <>
              <a href="#welcome">Welcome</a>
              <a href="#tactics">Sportradar Tactics</a>
              <a href="#documents">Documents</a>
            </>
          )}
          <a className="hover" onClick={handleSignout}>
            <PdsFileOutIcon hexColor={whiteColor} fixedSize="20px" />
          </a>
        </div>
      </div>
    </div>
  )
}
