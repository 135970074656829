import "./index.scss"

interface IProps {
  setVideoUrl: (value?: string) => void
  video?: string
}

export function Intro({ setVideoUrl, video }: IProps): JSX.Element {
  return (
    <>
      <div className="section-intro" onClick={() => setVideoUrl(video)}></div>
    </>
  )
}
