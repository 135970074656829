import { Video } from "../../../../common/types"
import { Feature } from "./Feature"

import "./index.scss"

interface IProps {
  setVideoUrl: (value?: string) => void
  video?: Video
  pdf?: { [key: string]: string }
}

interface IFeature {
  source: string
  type: "image" | "video" | "pdf"
  title: string
  id: string
}

export function Documents({ setVideoUrl, video, pdf }: IProps): JSX.Element {
  const features: IFeature[] = [
    {
      source: "/assets/betting.png",
      type: "pdf",
      title: "Commercial proposal",
      id: "betting",
    },
    {
      source: "/assets/odds.png",
      type: "pdf",
      title: "Presentation",
      id: "presentation",
    },
    {
      source: "/assets/document.png",
      type: "pdf",
      title: "Feedbacks on the agreement",
      id: "media",
    },
  ]

  return (
    <>
      <div className="section-ecosystem" id="documents">
        <h2>Documents</h2>
        <div className="features">
          {features.map((feature) => (
            <Feature
              title={feature.title}
              key={feature.title}
              type={feature.type}
              source={feature.source}
              video={feature.type === "video" ? video : undefined}
              setVideoUrl={setVideoUrl}
              className={feature.type === "video" ? "video" : undefined}
              id={feature.id}
              link={pdf ? pdf[feature.id] : undefined}
            />
          ))}
        </div>
      </div>
    </>
  )
}
