import { Fragment } from "react"
import { Feature } from "./Feature"

import "./index.scss"

interface IProps {
  video?: string
  poster?: string
}

export function SportradarTactics({ video, poster }: IProps): JSX.Element {
  const features = [
    {
      tag: "Eredivisie Trading Card Goalkeeper Back",
      title: "GOALKEEPER",
    },
    {
      tag: "Eredivisie Trading Card Defence Back",
      title: "DEFENCE",
    },
    {
      tag: "Eredivisie Trading Card Midfield Back",
      title: "MIDFIELD",
    },
    {
      tag: "Eredivisie Trading Card Attack Back",
      title: "ATTACK",
    },
  ]

  return (
    <div className="section-impact">
      <span className="anchor" id="tactics" />
      <h2>
        Sportradar <span className="red">Tactics</span>
      </h2>
      <p className="introduction">
        As the world&apos;s leading sports technology company, providing solutions across the global betting and sports
        ecosystem, Sportradar is uniquely positioned to support commercial growth, build and unlock audience, increase
        fan engagement all whilst delivering the highest standard of integrity and protection With our committed
        investment to innovation, tech and data, football fans will enjoy the ultimate fan experience.
      </p>
      {video && <video src={video} className="video" controls poster={poster} />}
      <div className="features">
        {features.map((feature) => (
          <Fragment key={feature.tag}>
            <Feature tag={feature.tag} title={feature.title} />
          </Fragment>
        ))}
      </div>
    </div>
  )
}
