import "./index.scss"

interface IProps {
  video?: string
}

export function Hero({ video }: IProps): JSX.Element {
  return (
    <div className="section-hero">
      <video src={video} poster="/assets/hero_poster.png" autoPlay muted loop playsInline />
      <div className="title-hero">
        <h2>EREDIVISIE & SPORTRADAR</h2>
        <h2>
          <span className="red">ONE TEAM</span> TO WIN.
        </h2>
      </div>
    </div>
  )
}
