export function Social(): JSX.Element {
  return (
    <div className="social">
      <a href="https://www.linkedin.com/company/sportradar/" target="_blank" rel="noreferrer">
        <img src="https://sportradar.com/wp-content/themes/sportradar/assets/images/svg/linkedin.svg" alt="linkedin" />
      </a>
      <a href="https://twitter.com/Sportradar" target="_blank" rel="noreferrer">
        <img src="https://sportradar.com/wp-content/themes/sportradar/assets/images/svg/twitter.svg" alt="twitter" />
      </a>
      <a href="https://www.youtube.com/sportradar" target="_blank" rel="noreferrer">
        <img src="https://sportradar.com/wp-content/themes/sportradar/assets/images/svg/youtube.svg" alt="" />
      </a>
      <a href="https://www.instagram.com/lifeatsportradar/?hl=en" target="_blank" rel="noreferrer">
        <img src="https://sportradar.com/wp-content/themes/sportradar/assets/images/svg/instagram.svg" alt="ig" />
      </a>
      <a href="http://www.facebook.com/pages/Sportradar/391162040945181" target="_blank" rel="noreferrer">
        <img src="https://sportradar.com/wp-content/themes/sportradar/assets/images/svg/facebook.svg" alt="fb" />
      </a>
    </div>
  )
}
